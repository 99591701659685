import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                About Home Owner Insurance
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] &&
                                                            <>
                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 2 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width">
                                                                                            What if my home is damaged to
                                                                                            the extent that I cannot reside in it? </div><img
                                                                            src={showResults[2] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="2"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[2] && `If your home is damaged to
                                                                                        that extent, your home insurance will cover alternate living
                                                                                        arrangements like hotel bills while repairs or replacements are in
                                                                                        process.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 3 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> What if I cannot get home
                                                                                            insurance easily because I am in a Wildfire zone? </div><img
                                                                            src={showResults[3] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="3"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[3] && `Typically, home insurance
                                                                                        covers damage caused by wildfires in states that aren't susceptible to
                                                                                        them. However, if you live in a state where wildfires are a common
                                                                                        event, you might need to buy additional coverage that includes damage by
                                                                                        wildfire in particular. Also, many states have special plans to meet the
                                                                                        needs of homeowners unable to find insurance in the traditional
                                                                                        marketplace due to specific hazards.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 4 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> What is a deductible?
                                                                          </div><img
                                                                            src={showResults[4] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="4"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">
                                                                          {showResults[4] && `A deductible is the amount
                                                                                        of money you need to pay out of your pocket before your home insurance
                                                                                        comes into effect. For instance, if there was water-related damage and
                                                                                        you had set your deductible at $1500, with the cost of
                                                                                        repair/replacement at $4000, you pay the $1500 and the rest is taken
                                                                                        care of by the insurer.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 5 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> What's the difference between
                                                                                            replacement cost and actual cash value? </div><img
                                                                            src={showResults[5] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="5"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">
                                                                          { showResults[5] && `Replacement cost is a
                                                                                        comprehensive coverage in your policy that pays to repair or replace the
                                                                                        damage caused by covered perils to your home and belongings as long as
                                                                                        the cost is within the limits of the coverage of the policy. Actual cash
                                                                                        value is when the insurance pays the depreciated value of your
                                                                                        belongings.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 6 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> Can I get a discount on my
                                                                                            home insurance with Bubble? </div><img
                                                                            src={showResults[6] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="34"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[6] && `If you're eligible, the
                                                                                        sure! Begin the application process to know how much discount you can
                                                                                        get.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 7 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> How much home insurance do I
                                                                                            need? </div><img
                                                                            src={showResults[7] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="7"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[7] && `There are many factors to
                                                                                        consider before zeroing in on how much coverage you need. But the factor
                                                                                        that is a make or break one is the cost to rebuild or replace any damage
                                                                                        done to your home and belongings.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 8 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> Can I add more coverage to my
                                                                                            home insurance policy? </div><img
                                                                            src={showResults[8] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="8"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[8] && `Yes, you can add or adjust
                                                                                        your coverage as you need. If you're planning on remodelling your home
                                                                                        or redoing your kitchen, it's best you adjust your policy to fit your
                                                                                        home's new value.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div className="col-lg-6 col-12">
                                                                <div className="faqSubContainer">
                                                                  <div className="faqFlex faq-container-align">
                                                                    <div className="faqLeftContainer row">
                                                                      <div className="col-sm-12 col-12"  onClick={( e ) => onClick( e, 9 )}>
                                                                        <div className="align-faq-que-box position-relative">
                                                                          <div  className="carrier-faq carrier-faq-width"> Will my home insurance cover
                                                                                            my small-scale business I run from my home? </div><img
                                                                            src={showResults[9] ? UpArrow : DownArrow}
                                                                            className="faq-dd faq-dd-width m-0" name="9"
                                                                            alt="Bubble Life Insurance"/></div>

                                                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{
                                                                          showResults[9] && `You can get additional
                                                                                        coverage to protect your small-scale business with our home insurance.
                                                                                        But we suggest you speak to our specialist to learn what are your
                                                                                        options.`}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
